<template>
  <div class="warp">
    <!-- 头部 -->
    <div class="warpTop">
      <!-- <div class="path"><span @click="saveImg('/')">首页</span></div> -->
      <div class="path">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>精品课程</el-breadcrumb-item>
          <el-breadcrumb-item>{{ zhibo.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="warpTopsCent">
        <!-- 左侧视频 -->
        <div class="warpTopsCentL"> <img style="width: 100%;height: 100%;" :src="zhibo.img" />
          <img style="position: absolute;right: 0;top: 0;width: 66px;height: 22px;" src="@/assets/img/partner/jingpin.png"
            alt="">
        </div>


        <!-- 右侧详情 -->
        <div class="warpTopsCentR">
          <!-- 标题 -->
          <div class="warpTopsCentR_title">
            <div class="title dis_flex">
              <!-- <div class="courType">直播</div> -->
              {{ zhibo.title }}
            </div>
            <div class="collect" @click="collectClick">
              <el-image v-if="collect === '0'" :src="require('@/assets/img/partner/collect.png')"></el-image>
              <el-image v-if="collect === '1'" :src="require('@/assets/img/partner/collect_a.png')"></el-image>
              <span class="collect_name">收藏</span>
            </div>
          </div>
          <div class="detail-ter flex-c" style="margin-top: 18px">
            <div class="ter-list">
              <span class="ter">主讲老师：</span>
              <div class="terbox" @click="goNext(`/faculty/teacherDetail?id=${zhibo.teacherId}`)">
                <el-image class="ter-img" :src="zhibo ? zhibo.tImg : ''" fit="cover" />
              </div>
            </div>
          </div>
          <div class="vip_box">
            <div class="content_box">
              <div class="vip_content">直播</div>
              <div class="vip_content">{{ zhibo.kcName }}</div>
              <div class="vip_content">{{ zhibo.allTime }}课时</div>
            </div>
          </div>
          <!-- 价格 -->
          <div v-if="priceShow">
            <div class="picr">
              <div class="spanb">
                <span>￥</span>{{ zhibo.jgPrice ? zhibo.jgPrice : 0 }}
              </div>
              <div class="btn">
                <div v-if="!IsPurchase" class="keep-learn keep-check" @click="yuyue(1)">
                  立即购买
                </div>
                <div v-else class="keep-learn" @click="yuyue(2)">已购买</div>
              </div>
              <!-- <div class="oldprice">￥{{ zhibo.olderPrice ? zhibo.olderPrice : 0 }}</div> -->
            </div>
            <!-- 报名资讯按钮 -->
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="box-content">
        <!-- 选项栏 -->
        <div class="warpnav">
          <div class="warpnavs">
            <!-- <div :class="navIndex == 1 ? 'on' : 'warpnavsCons'" @click="navs(1)">
              <span>课程详情</span>
              <div v-if="navIndex == 1"></div>
            </div> -->
            <div class="warpnavsCons" :class="navIndex == 1 ? 'on' : ''" @click="navs(1)">
              <span>课程详情</span>
              <div v-if="navIndex == 1" />
            </div>
            <div class="warpnavsCons" :class="navIndex == 2 ? 'on' : ''" @click="navs(2)">
              <span>课程列表</span>
              <div v-if="navIndex == 2" />
            </div>
            <!-- v-if="productLine == 2 && dataShow" -->
            <div class="warpnavsCons" :class="navIndex == 3 ? 'on' : ''" @click="navs(3)">
              <span>课程资料</span>
              <div v-if="navIndex == 3" />
            </div>
            <div v-if="productLine == 2" class="warpnavsCons" :class="navIndex == 4 ? 'on' : ''" @click="navs(4)">
              <span>课程测试</span>
              <div v-if="navIndex == 4" />
            </div>
            <div class="warpnavsCons" :class="navIndex == 5 ? 'on' : ''" @click="navs(5)">
              <span>课程评价 ({{ evaluate }})</span>
              <div v-if="navIndex == 5" />
            </div>
          </div>
        </div>
        <div class="dis_flex_start">
          <div v-if="navIndex == 1" class="warpCon">
            <div class="apple">
              <div class="appleLeft">
                <div class="appleLefts" v-html="ImgSizeLimit(zhibo.content)" />
              </div>
              <!-- 右侧推荐 -->
              <!-- <you-love :id="zhiboId" :type="2">相关课程</you-love> -->
            </div>
          </div>
          <!-- 直播章节 -->
          <div v-if="navIndex == 2" class="warpCon">
            <chater :id="zhiboId" :if-class="false" :class-id="classId" :cc-config="zhibo ? zhibo.ccConfig : ''"
              :purchase="IsPurchase" :list="zhibo.liveshowplaybackList" :class-product-line="classProductLine"
              :is-freeze="isFreeze" />
          </div>
          <!-- 课程资料 -->
          <div v-if="navIndex == 3" class="warpCon">
            <course-data v-if="navIndex == 3" :id="zhiboId" :type="1" :is-purchase="IsPurchase" />
          </div>
          <!-- 课程模考 -->
          <div v-if="navIndex == 4" class="warpCon">
            <course-task v-if="navIndex == 4" :id="zhiboId" :type="1" :is-purchase="IsPurchase" />
          </div>
          <!-- 课程评价 -->
          <div v-if="navIndex == 5" class="warpCon">
            <score-evaluation v-if="navIndex == 5" :type="2" :type-id="zhiboId" :type-nmae="zhibo.title"
              :is-purchase="IsPurchase" :course-data="zhibo" />
          </div>
          <you-love :id="zhiboId" class="youlove" :ifteacher="true" :type="2">其他课程推荐</you-love>
        </div>
        <!-- 课程详情内容 -->
      </div>
    </div>

    <div v-if="isFreeze == 1" class="mask_layer">课程已冻结，请联系老师</div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getLbClassCourseById } from "@/api/home";
import chater from "./components/chater.vue";
// import chater from "../../components/Know/course/chater.vue";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();
import YouLove from "@/components/Know/youLovePartner.vue";
import { getToken } from "@/api/cookies";
import CourseData from "@/components/Know/course/courseDataPartner.vue";
import courseTask from "@/components/Know/course/courseTaskPartner.vue";
import scoreEvaluation from "@/components/Know/course/scoreEvaluationPartner.vue";
import { Evaluation } from "@/api/scoreEvaluation";
import { Know } from "@/api/know";
import { getInfo } from "@/api/cookies";

const know = new Know();
import { checkStudyCode } from "@/api/home.js";
const evaluation = new Evaluation();
import { getCollectProduct, getCollectProductStatus } from "@/api/mykecheng";
export default {
  components: { chater, YouLove, CourseData, courseTask, scoreEvaluation },

  data() {
    return {
      zhibo: {},
      navIndex: 1,
      yuyues: 2,
      IsPurchase: false, // 章节列表
      zhiboId: null,
      classId: null,
      href: "",
      average: 0, // 评分
      productLine: null, // 1普通 2精品
      cardCode: null, // 学习卡id
      classProductLine: 0,
      dataShow: false,
      isFreeze: null, // 是否冻结 0 否 1 是
      priceShow: true,
      collect: "0",
    };
  },

  created() {
    this.cardCode =
      this.$route.query.cardCode == "undefined"
        ? null
        : this.$route.query.cardCode;
    this.checkStudyCode();
    this.classProductLine = this.$route.query.classProductLine
      ? Number(this.$route.query.classProductLine)
      : 0;
    this.href = window.location.href;
    this.zhiboId = this.$route.query.id;
    this.priceShow = this.$route.query.vip != "true";

    this.getLiveData();
    this.classId =
      this.$route.query.classId &&
        this.$route.query.classId != "undefined" &&
        this.$route.query.classId != "null" &&
        // eslint-disable-next-line use-isnan
        Number(this.$route.query.classId) != NaN
        ? this.$route.query.classId
        : null;
    this.loadgetDetail();

    evaluation.getPresentScore(2, this.zhiboId).then((res) => {
      this.average = Math.round(res.average);
    });
    know.updateCourseAllClicks(2, this.zhiboId);
  },
  methods: {
    // 收藏
    async collectClick() {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.collect === "0") {
        this.collect = "1";
      } else if (this.collect === "1") {
        this.collect = "0";
      }
      const res = await getCollectProduct({
        type: '2',
        typeId: this.zhibo.id,
        status: this.collect,
        stuId: userInfo.id,
      });
      if (res.code === 0) {
        this.$message.success(res.msg);
        this.loadgetDetail();
        this.getCollectProductStatus();
      }
      if (res.msg === "收藏成功") {
        this.collect = "1";
      }
      if (res.msg === "取消收藏") {
        this.collect = "0";
      }
    },
    // 查询是否收藏
    async getCollectProductStatus() {
      if (!localStorage.getItem("userInfo")) {
        return;
      } else {
        const res = await getCollectProductStatus({
          type: "2",
          stuId: JSON.parse(localStorage.getItem("userInfo")).id,
          typeId: this.zhibo.id,
        });
        console.log(res);
        if (res.data === false) {
          this.collect = "0";
        } else if (res.data === true) {
          this.collect = "1";
        }
      }
    },
    /* 验证学习卡 */
    checkStudyCode() {
      if (this.cardCode) {
        console.log(this.cardCode, "this.cardCode");
        const data = {
          cardCode: this.cardCode,
        };
        checkStudyCode(data).then((res) => {
          if (res.code == 0) {
            this.IsPurchase = true;
            this.$store.commit("IS_PURCHASE_CHANGE", true);
          }
        });
      }
    },
    // 直播课程详情信息
    loadgetDetail() {
      getLbClassCourseById(this.zhiboId, this.classId).then((res) => {
        if (!this.cardCode) {
          this.IsPurchase = res.data.IsPurchase;
        }
        this.productLine = res.data.details.productLine;
        this.zhibo = res.data.details;
        this.zhibo.jgPrice = res.data.jgPrice;
        this.zhibo.size = res.data.size;
        this.isFreeze = res.data.isFreeze;
        this.getCollectProductStatus();
        evaluation
          .getStudentEvaluateList(
            999999,
            1,
            2,
            this.zhiboId,
            1,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          )
          .then((res) => {
            this.evaluate = res.total
          });
        this.$forceUpdate();
      });
    },

    // 预约按钮
    yuyue(index) {
      this.yuyues = index;
      if (index == 1) {
        const token = getToken();
        if (token) {
          orderClient
            .addOrders(
              this.zhibo.id,
              localStorage.getItem("userId"),
              this.zhibo.title,
              2,
              this.zhibo.title,
              this.zhibo.kind
            )
            .then((res) => {
              if (res.code == 0) {
                /*   this.users = res.msg; */
                this.$router.push({
                  name: "高校个人中心订单页",
                  params: { orderNumber: res.msg },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          Vue.prototype.goLoginView(true);
          // this.$router.push({ name: "登录页", params: { hzc: 1 } });
        }
      }
    },
    getLiveData() {
      know.curCourseDocsList(1, 9999, this.zhiboId, 3, 1).then((res) => {
        if (res.rows.length > 0) {
          this.dataShow = true;
        }
      });
    },
    // 标签页切换
    navs(val) {
      this.navIndex = val;
    },
  },
};
</script>
<style lang="less" scoped>
.ter {
  color: #ffffff !important;
}

.warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // margin-bottom: 60px;

  // 头部
  .warpTop {
    width: 100%;
    height: 230px;
    background: #375669;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    padding: 0px calc(50% - 600px);

    .path {
      width: 1200px;
      height: 56px;
      padding: 20px 0;
      margin: 0 auto;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;

      /deep/ .el-breadcrumb__item {
        .el-breadcrumb__inner,
        .el-breadcrumb__separator {
          color: #fff;
        }
      }
    }

    .warpTopsCent {
      width: 100%;
      height: 142px;
      display: flex;
      justify-content: flex-start;
      align-items: start;

      // 左侧视频
      .warpTopsCentL {
        width: 252px;
        height: 142px;
        border-radius: 6px;
        opacity: 1;
        margin-right: 20px;
        flex-shrink: 0;
        position: relative;
      }

      // 右侧详情
      .warpTopsCentR {
        width: 100%;
        height: 100%;
        position: relative;

        .warpTopsCentR_title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            margin-top: 10px;
            height: 36px;
            line-height: 36px;
            font-size: 24px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #ffffff;

            .courType {
              height: 24px;
              background: #ffffff;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 1px solid #ff7b1c;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular;
              font-weight: 400;
              color: #ff7b1c;
              line-height: 22px;
              padding: 0px 11px;
              margin-right: 8px;
              flex-shrink: 0;
            }
          }

          .collect {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;

            .collect_name {
              margin-left: 3px;
              margin-top: -2px;
            }
          }
        }

        .vip_box {
          margin-top: 28px;

          .content_box {
            display: flex;
            margin-bottom: 24px;

            .vip_content {
              padding: 0 6px;
              height: 22px;
              line-height: 22px;
              margin-right: 10px;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 1px solid #fff;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }

            .m_r_2 {
              margin-right: 2em;
            }

            .em {
              letter-spacing: 2em;
            }
          }
        }

        // 价格
        .picr {
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .spanb {
            height: 36px;
            font-size: 24px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #f9431e;
            margin-right: 18px;

            span {
              font-size: 16px;
            }
          }

          .btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            // 立即报名
            .keep-learn {
              width: 130px;
              height: 44px;
              line-height: 44px;
              border-radius: 22px;
              background: linear-gradient(47deg, #ff6161 0%, #f52828 100%);
              box-shadow: 0px 3px 6px 1px rgba(246, 43, 43, 0.3);
              opacity: 1;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
            }
          }
        }

        // 报名资讯按钮
      }
    }
  }

  // 选项栏
  .warpnav {
    width: 1200px;
    height: 48px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    margin: 0 auto;
    padding: 0px 32px;
    line-height: 48px;

    // 缩宽
    .warpnavs {
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // 知识套餐概述
      /*  .warpnavsCons {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      } */
      // 竖线

      // 转换内容 选中
      .on {
        height: 100%;

        span {
          // font-weight: bold !important;
          color: #333333 !important;
        }

        div {
          width: 20px;
          height: 4px;
          background: #1e82f5;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          margin-left: calc(50% - 10px);
          margin-top: -3px;
        }
      }

      // 转换内容 未选中
      .warpnavsCons {
        height: 100%;
        margin-right: 40px;

        span {
          font-size: 16px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          cursor: pointer;
        }
      }
    }
  }

  // 直播课内容
  .warpCon {
    width: 852px;
    min-height: 593px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    margin: 10px 20px 0 0;
    overflow: hidden;
    background: #fff;

    // 缩宽
    .apple {
      width: 852px;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .appleLeft {
        width: 750px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .appleLefts/deep/img {
          width: 700px;
          height: auto;
          margin-top: 25px;
        }
      }

      .appleRight {
        width: 430px;
        height: 1292px;
        background: #ffffff;

        .appleRightTop {
          width: auto;
          height: 20px;
          margin: 16px 22px;
          border-left: 6px solid #3855f9;
          padding: 0px 12px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7e7e7e;
        }

        img {
          width: 376px;
          height: 209px;
          margin-top: 25px;
          margin-left: 27px;
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}

.content {
  display: flex;
  width: 1220px;
  margin: 20px calc(50% - 610px);

  .box-content {
    width: 1200px;
    margin-right: 20px;
  }

  .youlove {
    margin-top: 10px;
    width: 328px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    opacity: 1;
    flex-shrink: 0px;
  }
}

.el-popover {
  overflow: hidden;
  padding: 0px;
}

.mask_layer {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  position: fixed;
  background-color: #000000;
  opacity: 0.6;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.terbox {
  width: 28px !important;
  height: 28px !important;
  cursor: pointer;

  .ter-img {
    width: 28px;
    height: 28px;
    display: grid;
  }
}
</style>

<template>
  <div class="coupon-list">
    <div class="coupon-item" v-for="(item, index) in keDataList" :key="index">
      <!--   <i class="iconfont icon-a-zu939 img" /> -->
      <img v-if="item.filetype == 'excel'" class="img" src="@/assets/img/partner/xls.png" />
      <img v-else-if="item.filetype == 'word'" class="img" src="@/assets/img/partner/word.png" />
      <img v-else-if="item.filetype == 'pdf'" class="img" src="@/assets/img/partner/pdf.png" />
      <img v-else-if="item.filetype == 'ppt'" class="img" src="@/assets/img/partner/ppt.png" />
      <img v-else class="img" src="@/assets/img/partner/pdf.png" />
      <div class="item-content">
        <div class="item-title ellipsis">{{ item.fileName }}</div>
      </div>
      <!-- <div class="btn_item" @click="openKowledge(item)">知识点</div> -->
      <div class="keep-learn" @click="delow(item)">下载</div>
      <!--   <i class="iconfont icon-iconfontzhizuobiaozhun023146 delow" @click="delow(item)" /> -->
    </div>
    <div class="nothing" v-if="!keDataList || keDataList.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无资料哦~</div>
    </div>
    <el-dialog :visible.sync="kowledgePointsVisible" width="50%" :before-close="kowledgePointsClose" :show-close="false"
      append-to-body>
      <div class="kowledgePoints_wrap">
        <kowledgePoints ref="kowledgePoints" type="4"></kowledgePoints>
        <div class="determine_btn" @click="kowledgePointsClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { Know } from "@/api/know";
let know = new Know();
import { getInfo, getToken } from "@/api/cookies";
import { DataClient } from "@/api/dataClient";
let dataClient = new DataClient();
import kowledgePoints from "@/components/kowledgePoints.vue";
export default {
  data() {
    return {
      userInfo: null,
      keDataList: [],
      dialogVisible: false,
      kowledgePointsVisible: false,
    };
  },
  components: {
    kowledgePoints,
  },
  props: {
    isPurchase: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String | Number,
    },
    type: {
      type: Number /* 1 直播 2录播 3音频 4 图文 5面授 */,
    },
  },
  created() {
    let that = this;
    this.userInfo = getInfo();

    if (!this.id) {
      return;
    }
    know.curCourseDocsList(1, 9999, this.id, 3, this.type).then((res) => {
      this.keDataList = res.rows;
      for (let item of this.keDataList) {
        item.filetype = Vue.prototype.getFileType(item.filePath);
        Vue.prototype.getFilesize(item.filePath, function (size) {
          let sizeVal = (size / 1028).toFixed(0) + "k";
          let data = (size / 1028 / 1024).toFixed(0);
          item.size = data > 0 ? data + "M" : sizeVal;
          that.$forceUpdate();
        });
      }
    });
  },
  methods: {
    /* 下载 */
    delow(item) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.isPurchase) {
        let data = {
          zlId: item.id,
          linkLocation: item.filePath,
          name: item.fileName,
          type: 0,
        };
        dataClient.downloadRecord(data);
        Vue.prototype.DownloadDoc(item.filePath, item.fileName);
      } else {
        this.$notify({
          title: "错误",
          message: "未购买，无法下载, 请先去购买",
          type: "error",
          duration: 2000,
        });
      }
    },
    openKowledge(item) {
      this.kowledgePointsVisible = true;
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(item.id);
      });
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
/* 随堂资料 */
.coupon-list {
  padding: 20px;

  .coupon-item {
    width: 811px;
    height: 70px;
    margin-bottom: 10px;
    background: #f9f9f9;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;

    .img {
      width: 33.68px;
      height: 37.12px;
      flex-shrink: 0;
    }

    .item-content {
      flex: 1;
      text-align: left;
      margin: 0px 12px;

      .item-title {
        height: 14px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        width: 590px;
      }

      .item-size {
        margin-top: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
    }

    .btn_item {
      flex-shrink: 0;
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #2586f5;
      margin-right: 20px;
      cursor: pointer;
    }

    .keep-learn {
      flex-shrink: 0;
      width: 108px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px 18px 18px 18px;
      opacity: 1;
      border: 1px solid #2586f5;
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #2586f5;
      margin-right: 39px;
      cursor: pointer;
    }
  }

  .kowledgePoints_wrap {
    padding-bottom: 20px;

    .determine_btn {
      width: 40%;
      height: 40px;
      margin: 0 auto;
      margin-top: 20px;
      background-color: #ff5e51;
      color: #ffffff;
      font-weight: 500;
      border-radius: 20px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div class="chater">
    <div class="chater_item" v-for="(item, index) in CourseList" :key="index">
      <div class="item_trySee" v-if="item.isTrial == 1" @click.stop="gkcb(item)">
        <div class="try">
          <i class="iconfont icon-a-Union21" />
          <div class="name">{{ item.title }}11</div>
          <div class="item_see">试看</div>
        </div>
        <div class="see">
          <div class="handouts" v-if="ifClass ? dealDocType(item, 2) : item.isHasContantJY == 'true'
            " @click.stop="goData(item, 4)">
            随堂讲义
          </div>
          <div class="handouts" v-if="ifClass ? item.contacList.length > 0 : item.isHasContant == 'true'
            " @click.stop="cgeckTask(item)">
            随堂资料
          </div>
          <div class="handouts" v-if="ifClass ? dealDocType(item, 1) : item.isHasDoc == 'true'"
            @click.stop="goData(item, 2)">
            随堂测试
          </div>
        </div>
      </div>
      <div @click.stop="gkcb(item)" class="item_try" v-else>
        <div class="try">
          <i style="margin-right: 10px;" v-if="IsPurchase" class="iconfont icon-a-Union21" />
          <el-image v-else :src="require('@/assets/img/partner/lock.png')"></el-image>
          <div class="name">{{ item.title }}</div>
        </div>
        <div class="see">
          <div class="handouts" v-if="ifClass ? dealDocType(item, 2) : item.isHasContantJY == 'true'
            " @click.stop="goData(item, 4)">
            随堂讲义
          </div>
          <div class="handouts" v-if="ifClass ? item.contacList.length > 0 : item.isHasContant == 'true'
            " @click.stop="cgeckTask(item)">
            随堂资料
          </div>
          <div class="handouts" v-if="ifClass ? dealDocType(item, 1) : item.isHasDoc == 'true'"
            @click.stop="goData(item, 2)">
            随堂测试
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getLbClassCourseById } from "@/api/home";
import { IsLive } from "@/api/emun";
import { getInfo, getToken } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
import { courseCheckPlay } from "@/api/know";
const answerClient = new AnswerClient();
export default {
  props: {
    id: {},
    playid: {},
    list: {},
    classId: {},
    classProductLine: {},
    ccConfig: {
      type: String,
    },
    purchase: {
      type: Boolean,
    },
    ifClass: {
      type: Boolean,
    },
    cardCode: null,
    isFreeze: null,
  },
  data() {
    return {
      userInfo: null,
      IsLive: IsLive,
      CourseList: [],
      dialogVisible: false,
      IsPurchase: false,
      lookDeatil: null,
      showLookDetail: false,
      goBack: false,
    };
  },
  // props: ["id", "playid", "list", "playid", "classId", "ccConfig", "purchase"],
  watch: {
    // list() {
    //   this.IsPurchase = this.purchase;
    //   this.CourseList = this.list;
    // },
  },
  created() {
    this.userInfo = getInfo();
    console.log(this.list, 'this.list');
    // if (this.list) {
    //   this.IsPurchase = this.purchase;
    //   this.CourseList = this.list;
    // } else {
    getLbClassCourseById(this.id, this.classId).then((res) => {

      this.IsPurchase = res.data.IsPurchase;

      this.CourseList = res.data.details.liveshowplaybackList;
    });
    // }
  },
  methods: {
    /* 判断是否有讲义 */
    dealDocType(item, type) {
      let temp = false;
      if (item.docList && item.docList.length > 0) {
        item.docList.forEach((element) => {
          if (element.docType == type) {
            temp = true;
          }
        });
      }
      return temp;
    },
    tChuang() {
      this.dialogVisible = false;
    },
    stop() {
      return;
    },
    // 观看重播按钮  切换课程章节播放
    gkcb(v) {
      const sub = {
        id: v.id,
        classId: this.classId,
        startTime: v.startTime,
        classProductLine: this.classProductLine,
        liveId: v.liveId,
        isTrial: v.isTrial,
        backAudition: v.backAudition,
        IsPurchase: this.IsPurchase,
        chapterName: v.title,
      };
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key];
        }
      }
      const data = Vue.prototype.export(sub);
      let userInfo = getInfo();
      // 未登录可以直接去试听
      if (!userInfo) {
        if (v.isTrial == 1 && v.isLive == 3 && !this.IsPurchase) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang?${data}`,
          });
          window.open(routeUrl.href, "_blank");
          return;
        }
        Vue.prototype.goLoginView(false);
        return;
      } else {
        // 已登录试听
        if (v.isTrial == 1 && v.isLive == 3 && !this.IsPurchase) {
          this.limitCheck(v, data);
          return;
        }
      }
      if (v.isTrial == 1 && v.isLive == 3 && !this.IsPurchase) {
        const routeUrl = this.$router.resolve({
          path: `/livebofang?${data}`,
        });
        window.open(routeUrl.href, "_blank");
        return;
      }
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (!this.IsPurchase && v.isTrial != 1) {
        // this.$message.error("尚未购买，请先去购买课程！");
        this.dialogVisible = true;
        return;
      }
      if (this.isFreeze == 1) {
        this.$message.error("课程已冻结");
        return;
      }
      if (v.isLive == 0) {
        this.$message.error("课程还未到开播时间");
        return;
      }
      if (v.isLive == 2) {
        this.$message.error("课程直播结束");
        return;
      }
      this.limitCheck(v, data);
    },
    // 看课限制
    async limitCheck(item, data) {
      const { code, msg } = await courseCheckPlay({
        courseType: "1",
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1,
      });
      if (code == 0) {
        const routeUrl = this.$router.resolve({
          path: `/livebofang?${data}`,
        });
        window.open(routeUrl.href, "_blank");
      } else {
        this.lookDeatil = msg;
        this.showLookDetail = true;
        this.goBack = false;
      }
    },
    // 直播
    gkZb(v) {
      const userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.isFreeze == 1) {
        this.$message.error("课程已冻结");
        return;
      }

      const sub = {
        id: v.id,
        classId: this.classId,
        startTime: v.startTime,
        classProductLine: this.classProductLine,
        chapterName: v.title,
      };
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key];
        }
      }
      const data = Vue.prototype.export(sub);
      // this.$router.push({
      //   path: `/livebofang?${data}`,
      // });
      courseCheckPlay({
        courseType: "1",
        userId: this.userInfo.id,
        chapterId: v.id,
        source: 1,
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang?${data}`,
          });
          window.open(routeUrl.href, "_blank");
        } else {
          this.lookDeatil = res.msg;
          this.showLookDetail = true;
          this.goBack = false;
        }
      });
    },
    // 课堂作业
    cgeckTask(item) {
      let sId = item.sId;
      let title = item.title;
      if (this.ifClass) {
        title = item.contactList[0].sjName;
        sId = item.contactList[0].cContactid;
      }
      answerClient.selectPaperSubjectList(sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            title,
            sId,
            2,
            this.ifClass ? 1 : 2,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            this.playid,
            this.classId,
            this.cardCode
          );
        }
      });
    },
    closeLook() {
      this.showLookDetail = false;
    },

    /* 随堂资料 */
    goData(item, type) {
      const userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true;
        return;
      }
      window.open(
        `/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`,
        "_blank"
      );

      // this.$router.push(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`);
    },
    // 随堂资料
    /*  goData(item) {
      if (!this.IsPurchase) {
        this.dialogVisible = true;
        return;
      }
      // data/daclickackageDetail?packageId=579
      this.$router.replace(`/ketangziliao?id=${item.id}&typebs=1`);
    }, */
  },
};
</script>
<style lang="less" scoped>
.chater {
  width: 100%;
  padding: 20px;

  .chater_item {
    width: 100%;
    background: #f9f9f9;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-bottom: 10px;
    padding: 13px 20px 13px 32px;

    .item_trySee {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .try {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .name {
          margin-left: 14px;
          cursor: pointer;
          font-size: 16px;
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          color: #333333;
        }

        .item_see {
          margin-left: 10px;
          width: 34px;
          height: 16px;
          background: rgba(0, 109, 231, 0.06);
          border-radius: 8px 8px 8px 8px;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #006de7;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .see {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .handouts {
          width: 72px;
          height: 24px;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #2586f5;
          font-size: 12px;
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          color: #2586f5;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 12px;
        }
      }
    }

    .item_try {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .try {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        /deep/ .el-image {
          width: 13.6px;
          height: 16px;
          margin-right: 15px;
        }

        .name {
          font-size: 16px;
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          color: #808695;
          cursor: pointer;
        }
      }

      .see {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .handouts {
          width: 72px;
          height: 24px;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #b9bfc4;
          font-size: 12px;
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          color: #777A82;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
